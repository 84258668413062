import React, { useEffect, useState } from "react"
import * as Style from "./ComingSoon.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import InitiativeListItem from "../initiative-list-item/InitiativeListItem"

const query = graphql`
  query {
    allStrapiInitiative {
      nodes {
        slug
        strapi_id
        id
        area_en
        area_bg
        post_code
        haeding {
          data {
            haeding
          }
        }
        heading_media {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 300
                height: 300
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`

const ComingSoon = ({ setFromIdeaGallery }) => {
  const data = useStaticQuery(query)
  const initiativesData = data.allStrapiInitiative
  const [sortedArr, setSortedArr] = useState([])

  useEffect(() => {
    setFromIdeaGallery(true)
  }, [])

  useEffect(() => {
    const sorted = [...initiativesData.nodes]
    sorted.sort((a, b) => a.area_bg.localeCompare(b.area_bg))
    setSortedArr(sorted)
  }, [])

  return (
    <div className={`main-container ${Style.commingSoonWrapper}`}>
      <h2>Инициативи 2023</h2>
      <div className={Style.initiativesWrapper}>
        {sortedArr.length !== 0 &&
          sortedArr.map(item => {
            return (
              <div key={item.id} className={Style.innerWrapper}>
                <InitiativeListItem data={item} />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ComingSoon
