import React, { useEffect, useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import InformationSection from "../components/information-section/InformationSection"
import Layout from "../components/layout/Layout"
import SubNav from "../components/sub-nav/SubNav"
import SectionHeading from "../components/UI/section-heading/SectionHeading"
import ComingSoon from "../components/coming-soon/ComingSoon"
import { Context } from "../context/SiteProvider"

const query = graphql`
  query {
    allStrapiArchive {
      nodes {
        section_heading {
          data {
            section_heading
          }
        }
        archive_information {
          id
          main_heading {
            data {
              main_heading
            }
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          text {
            data {
              text
            }
          }
          gallery {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          bottom_text {
            data {
              bottom_text
            }
          }
          logos {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          order
        }
        archive_button {
          id
          title
        }
      }
    }
  }
`

const Archive = () => {
  const archiveData = useStaticQuery(query)
  const data = archiveData.allStrapiArchive.nodes[0].archive_information
  const buttonData = archiveData.allStrapiArchive.nodes[0].archive_button
  const sectionHeading =
    archiveData.allStrapiArchive.nodes[0].section_heading.data.section_heading

  const reversed = [...data].reverse()

  const {
    setFromIdeaGallery,
    setCommingSoon,
    commingSoon,
    setCurrentFilter,
    currentFilter,
  } = useContext(Context)
  const [dataArray, setDataArray] = useState([])

  useEffect(() => {
    if (currentFilter === "all") {
      setDataArray(reversed)
    } else {
      const filtared = reversed.filter(item => {
        return item.order === currentFilter
      })
      if (filtared.length === 0) {
        setCommingSoon(true)
      } else {
        setCommingSoon(false)
      }
      setDataArray(filtared)
    }
  }, [currentFilter])

  return (
    <Layout>
      <SubNav
        buttons={buttonData}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
      />
      <SectionHeading heading={sectionHeading} />
      {
        <div>
          {!commingSoon &&
            dataArray.map(item => {
              return <InformationSection key={item.id} data={item} />
            })}
        </div>
      }
      {commingSoon && <ComingSoon setFromIdeaGallery={setFromIdeaGallery} />}
    </Layout>
  )
}

export default Archive
